/*
=========================================================
* Money Mine React - v2.1.0
=========================================================

* Product Page: https://www.money-mine.com/product/material-kit-react
* Copyright 2023 Money Mine (https://www.money-mine.com)

Coded by www.money-mine.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Money Mine React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// Money Mine React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import Pages from "pages/Presentation/sections/Pages";
import Testimonials from "pages/Presentation/sections/Testimonials";
import Download from "pages/Presentation/sections/Download";

// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/gif/bg-presentation.gif";
// import bgImage from "assets/images/bg-about-us.jpg";

function PrivacyPolicy() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        // action={{
        //   type: "external",
        //   route: "https://www.money-mine.com/product/material-kit-react",
        //   label: "free download",
        //   color: "info",
        // }}
        transparent
        light
      />
      {/* <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      > */}
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Privacy Policy {" "}
            </MKTypography>
            {/* <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Coming soon..
            </MKTypography> */}
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >

        <MKBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item sx={{ textAlign: { xs: "left", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" color="text" >
                  <strong>Money Mine Company Limited</strong><br /><br /> {" "}
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  Money Mine Company Limited ("Company”or "we") realizes the high importance of protecting personal data of past users, current users and future users, as well as related persons ("user" or "you"). We therefore prepare this Privacy Policy for Users ("Privacy Policy") to inform you of details and how we collect, use or disclose your personal data with respect to any services and all related services of the Company, regardless of whether the user has registered to open an account, including but not limited to, Royalty Program (“Services”) or not. In order to inform you of the rights you are entitled to under the applicable data protection laws. The Company recommends that users read this Privacy Policy to acknowledge and understand the objectives of collection, use, and disclosure of user’s personal data by the Company.
                  <br /><br />
                  We reserve the right to amend, update or supplement the content of this Privacy Policy to comply with the amendments of Services, including related laws and regulations without prior notice, unless we are required by law to notify you of such changes. We therefore encourage you to review our revisions to this Privacy Policy from time to time. Revisions will be effective immediately upon our announcement of the revised Privacy Policy to be in place of the previous version.
                  <br /><br />
                  Under this Privacy Policy, "Personal Data" means any information which is capable of identifying an individual, whether directly or indirectly. "Sensitive Personal Data" means Personal Data as specified in Section 26 of the Personal Data Protection Act B.E. 2562 (2019) and/or as announced by the Personal Data Protection Committee.
                  <br /><br />

                </MKTypography>
                <MKTypography variant="h4" fontWeight="bold" color="text">
                  <strong>1. OBJECTIVES</strong><br /><br /> {" "}
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  This Policy is purported to inform you, as the owner of Personal Data, of the details and how the Company collect, use or disclose your Personal Data, as well as your legal rights in relation to Personal Data.
                  <br /><br />
                  This Privacy Policy is subject to the Personal Data Protection Act B.E. 2562 (2019), where the Company, having authority to make decisions on the collection, use or disclosure of Personal Data, is called “Data Controller", and you are deemed as "Data Subject" in accordance with this law.
                  <br /><br />
                </MKTypography>
                <MKTypography variant="h4" fontWeight="bold" color="text">
                  <strong>2. PERSONAL DATA TO BE COLLECTED</strong><br /><br />
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  <strong>2.1 Collection channels</strong>
                  <br /><br />
                  Company may collect your Personal Data directly from you or indirectly from other sources, namely: <br /><br />
                  (1) Company may collect your Personal Data directly from you when you are applying and/or registering to use the Company’s Services, including accessing to various information or entering into transactions with the Company.
                  <br />
                  (2) Company may collect your Personal Data from other sources, such as companies which operate under affiliated companies, business partners, service providers, relevant governmental agencies, other third parties or other public sources and third parties’ websites (such as social media platforms).
                  <br /><br />
                  <strong>2.2 Types of Personal Data we collect about you</strong>
                  <br /><br />
                  Company may collect, use, disclose and/or cross-border transfer of different types of your Personal Data as you have a relationship with the Company as stated below, which includes but is not limited to:
                  <br /><br />
                  (1) Personal information, such as name, surname, date of birth, nationality, age, sex, occupation, ID card number including the number on the back of the ID card (Laser code), passport number (for foreigners), ID photo appearing on the user’s ID card, copy of ID card, user’s selfie photo, signature.
                  <br />
                  (2) Contact information, such as user's phone number, user's address, user’s geographical location, workplace, email address, LINE ID.
                  <br />
                  (3) Transaction information related to users’ use of the Services, such as user's transaction information interacted with other users, credit card information as registered by the user, transaction information related to top-ups, withdrawals, money transfers, as well as the execution of purchases of goods/services in accordance with the purpose of user’s use of the Service, financial background, financial statement, payment, borrowing, investment.
                  <br />
                  (4) User's credit/debit card information, which includes card numbers and expiration date, user’s designated phone number to complete the payment transaction.
                  <br />
                  (5) Device or tool information, such as IP Address, MAC Address, Cookie, website traffic log, device data.
                  <br />
                  (6) Information from the LINE Application, such as username, icon image, update status, and user ID as provided by the service provider of LINE Application, friend lists, email address, phone number, status of registration and registration date, country information, date of termination of service, account status, current version of LINE Application in use and details of the device.
                  <br />
                  (7) Other information, such as results of the Know-Your-Customer (KYC) execution, information of joining events and meetings, acknowledgment of participation in activities and meetings, interests, survey results, complaint information and/or any other information we may request from you in support of entering into contracts, services, or other operations related to use of our products or services.
                  <br />
                  (8) Sensitive Personal Data, such as your religion data (as it appears on a copy of ID card), and biometric data such as facial recognition.
                  <br /><br />
                  <strong>2.3 Personal Data of minors, incompetent or quasi-incompetent persons</strong>
                  <br /><br />
                  We do not collect, use or disclose Personal Data of minors, incompetent persons or quasi-incompetent persons, and we will not allow such individuals to apply for or use our products or services if we are unable to rely on other legal bases or have obtained consent from the parents and/or guardians (as the case may be).
                  <br /><br />
                  If we realize that we collected Personal Data of minors, incompetent persons or quasi-incompetent persons without consent or any other legal bases, we will proceed to delete such Personal Data in accordance with our internal procedures, and may need to stop providing products or services to them unless we are able to rely on a legal basis other than consent.
                  <br /><br />
                  <strong>2.4 Personal Data of third parties</strong>
                  <br /><br />
                  If you provide Personal Data of a third party (such as family members, friends, or contact persons) to us, please ensure that you have the right or authority to provide such Personal Data in the manner that enable us to collect, use or disclose such Personal Data in accordance with this Privacy Policy. This includes notifying such third parties of the details of this Privacy Policy, and/or obtain consent from those data subjects (if required) on behalf of us.
                  <br /><br />
                </MKTypography>
                <MKTypography variant="h4" fontWeight="bold" color="text">
                  <strong>3. PURPOSES OF OUR COLLECTION, USE, AND/OR DISCLOSURE OF YOUR PERSONAL DATA</strong><br /><br />
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  <strong>3.1 Purposes based on consent basis</strong><br />
                  <strong>3.1.1 General Personal Data – for the purposes of marketing activities, which the Company cannot rely on other legal bases:</strong><br /><br />
                  To communicate, provide information, offer products and services specifically selected for users, including special offers, promotions, campaigns, marketing/promotional activities and sales, benefits, as well as to conduct surveys related to products and services of the Company and those of affiliated companies, regardless of whether we handle it by ourselves or authorize other third parties to process it on our behalf, through various channels which includes, but not limited to, chat, phone calls, emails or social media.
                  <br /><br />
                  <strong>3.1.2 Sensitive Personal Data – for collection, use and/or disclosure of Sensitive Personal Data, which the Company cannot rely upon any other legal bases except consent:</strong>
                  <br /><br />
                  (1) Religion data (as it appears on a copy of ID card) – for the purpose of verification and authentication of your identity.
                  <br />
                  (2) Biometric data such as facial recognition – for the purpose of verification and authentication of the identity of a person who applies for our Services.
                  <br /><br />
                  If we rely on your consent to collect, use or disclose your Personal Data, you have the right to withdraw your consent at any time. Your withdrawal of consent does not affect past activities related to the collection, use or disclosure of Personal Data for which you have given your consent prior to your withdrawal.
                  <br /><br />
                  <strong>3.2 Purposes based on legal bases other than obtaining consent</strong>
                  <br /><br />
                  Company may rely on the following legal bases to collect, use or disclose your Personal Data: (1) the basis for the performance of the contract or for any performance in accordance with the contract or to enter into a contract; (2) the basis for complying with the laws or the Company’s duty to comply with the laws; (3) the basis for the Company’s and third parties’ legitimate interest which is proportionate to interests and fundamental freedoms in relation to the protection of your Personal Data; (4) the basis for prevention or suppression of harm to the life, body or health of a person; (5) the basis for public interest to carry out missions in the public interest or performing duties in the exercise of state powers; (6) the basis for the establishment and defense of future legal claims; (7) the basis for the necessity of complying with the laws to achieve objectives related to the important public interest, and/or other legal bases on which the Company can be relied upon under the relevant laws.
                  User may decide whether to submit Personal Data to the Company or not. User should verify that the Personal Data submitted to the Company is complete, true and correct. In the event that the Company needs to collect Personal Data in order to comply with the laws or contracts, or to enter into a contract between the user and the Company but the Company does not receive the Personal Data as requested, the Company may not be able to provide Services as requested by the user or carry out activities as stated per the purposes specified below.
                  <br /><br />
                  The following purposes may apply to you.
                  <br /><br />
                  <strong>3.2.1 To enable you to use the products and/or services of the Company according to the objectives of the contract you enter with the Company or for use in the execution of your request before using the Company's products and/or services, such as</strong>
                  <br /><br />
                  (1) Verification and authentication of your identity or any information, approval, recording and review of information, providing products and/or services such as payment of money or any asset, money transfers, withdrawals, deposits, loan, investment.
                  <br />
                  (2) Communication, such as communications about the Company's products and services, those of affiliated companies or business partners (e.g. communicating through document submissions, answering questions, responding to requests or reporting on progress).
                  <br />
                  (3) any other actions in connection with the product and/or services, e.g. processing, contacting, notifying, handing over work to third party service providers, assignment of rights and/or duties, notification of payment of debt or renewal of various products and/or services, and debt collection.
                  <br /><br />
                  <strong>
                    3.2.2 To perform duties in accordance with the applicable laws, such as(1) Compliance with orders, facilitation or cooperation with the courts, government agencies, regulating and supervising bodies, law enforcement agencies when we have reasons to believe that such laws and/or orders are required, e.g. establishing a database about fraud for fraud tracking and increasing the efficiency of the Company's fraud risk management as required by relevant laws. This may include disclosing your Personal Data to comply with the laws or for legal proceedings, internal investigations, or establishing legal claims.
                  </strong>
                  <br /><br />
                  (2) Compliance with payment system laws, announcements, regulations, policies of the Bank of Thailand, financial institution laws, securities and security exchange laws, policies of the Securities and Exchange Commission, life insurance laws, non-life insurance laws, tax laws, anti-money laundering laws, laws on the prevention and suppression of terrorism financing and the proliferation of weapons of mass destruction, computer laws, bankruptcy laws, consumer protection laws and other laws that the Company has to comply with, both in Thailand and overseas, including announcements and regulations issued under such laws, but not limited to, verification and authentication, for example, to verify and authenticate identity or any information, to record and verify information, to know customers (KYC) or Customer Due Diligence (CDD).
                  <br />
                  (3) Updating your Personal Data which the Company has recorded per your submission.
                  <br /><br />
                  <strong>
                    3.2.3 To carry out necessary operation under the Company's legitimate interests or that of another person or juristic person without exceeding the scope that you can reasonably expect, such as
                  </strong>
                  <br /><br />
                  (1) Audio recording via call center.
                  <br />
                  (2) Maintaining a relationship with user e.g. handling complaints, satisfaction assessment, customer care conducted by the Company's employees, notifying or offering useful products and/or services in the same type that you have already registered with the Company.
                  <br />
                  (3) Risk management, regulation and examination, internal management including delegating to affiliated companies for these purposes under the group companies' Personal Data Protection Policy (Binding Corporate Rules).
                  <br />
                  (4) Anonymize Personal Data to be incapable of identifying a particular individual.
                  <br />
                  (5) Prevention, coping, reducing the risk of fraudulent actions, cyber threat, default or breach of contract (e.g. bankruptcy information); violation of different laws (e.g. money laundering, terrorism financing and proliferation of weapons of mass destruction, offenses relating to property, life, body, liberty or reputation). This includes sharing Personal Data to raise the standard of work of affiliated companies to prevent, cope and reduce the above risks.
                  <br />
                  (6) Collection, use and/or disclosure of Personal Data of directors, authorized persons, agents of corporate customer.
                  <br />
                  (7) For the purpose of communicating and carrying out expected marketing activities, e.g. any communication and facilitation, presentation and introduction of appropriate products or services, marketing news alert, re-marketing conduct, ads, benefits, sales, special offers, notifications, newsletters, progress reports, announcements, promotional activities, news and information about the Company’s products or services, or those of affiliated companies and the Company's business partners, as well as public relations and invitations to participate in various activities, satisfaction surveys, contact, video recording and audio recording related to meetings, trainings, recreation or running booths.
                  <br />
                  (8) Collection, use and/or disclosure of Personal Data of persons who have been granted receivership by court.
                  <br />
                  (9) Pick-up and delivery of parcels.
                  <br />
                  (10) Prevention of fraud or use of various services without permission.
                  <br />
                  (11) Information technology management, such as the organization of communication systems and information technology security systems for the purposes of providing smooth and non-interrupted Services to users (including but not limited to software upgrades and various service improvements, data storage and backup in the work system or on the cloud, management of information technology infrastructure, financial systems, accounting and debt collection. Maintaining security relating to information technology, the Company's technological operations (e.g. payment systems via the Internet (Online Payment), management of applications, websites or other service platforms), correcting and updating information on various databases).
                  <br />
                  (12) For the operation of the website, application and platform, for example to maintain, operate, monitor, observe and manage the website, application and platform to facilitate and ensure that the website, applications and platforms run smooth, efficient and safe. To facilitate the use of the website, application and platform, to improve the roadmap and content of the website, application and platform.
                  <br />
                  (13) Handling complaints, suggestions, inquiries which allows the Company to deal with questions from users effectively.
                  <br />
                  (14) Taking care of business continuity, recording and establishing related database, blacklisting, documentation management, tax management, product and service management, provide information about the development of new services about various services in the future, accumulate statistics on the use of various services, research and analysis, improvement and development of the Company's products or services, designing new products or services, enhancing the customer experience, profiling, analyzing and processing behavior of using the website, application or platform, providing other services, analyzing and forecasting the Company’s sales performance or any other statistical analysis or research, any other operation to enable you to participate in and organize such activities, management of the Company’s asset.
                  <br />
                  (15) Perform identity verification when the Company receives a request or an order from a user.
                  <br />
                  (16) Inform the relevant important information to the users, and to contact the users as the Company considers appropriate and/or any other actions to facilitate the use of our products or services.
                  <br />
                  (17) Provide information to the merchants in case of refund, cancellation or payment in relation to the purchase of goods and services of users through various services.
                  <br />
                  (18) Conducting internal audits, maintaining the legitimate interests of the Company, prevent, detect and investigate crimes, analyze and manage commercial risks, and conducting investigations into disputes, collection of goods/services (billing) or any fraud investigation.
                  <br />
                  (19) In the case of any claim, action or proceeding (including but not limited to drafting and reviewing documents, transactional documents, obtaining legal opinions and litigation) and/or defending and enforcing the contractual rights and obligations of the Company.
                  <br />
                  (20) To comply with and implement any laws, regulations, rules, codes of practice or guidelines issued by a legal or regulatory authority, which the Company is obliged to comply with (including but not limited to responding to complaints initiated by regulators, disclosures to regulators and audits, in-depth examination and investigations).
                  <br />
                  (21) Protection or suppression of harm to a person's life, body, health or property, such as for the control of communicable diseases or epidemics, catching theft, taking action in an emergency.
                  <br />
                  (22) Business transfers, such as in the event of a sale, transfer, merger, reorganization or other similar events. The Company may transfer Personal Data to third parties, whether single or multiple recipients, as part of that transaction.
                  <br /><br />
                </MKTypography>
                <MKTypography variant="h4" fontWeight="bold" color="text">
                  <strong>4. TO WHOM MAY WE DISCLOSE YOUR PERSONAL DATA?</strong><br /><br />
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  The Company may disclose your Personal Data to others under your consent or under the rules permitted by law to disclose. The person or entity who is the recipient of such Personal Data will collect, use and/or disclose your Personal Data to the extent that you have given your consent or the scope involved in this Policy.
                  <br /><br />
                  The Company may disclose your Personal Data for various purposes, such as to provide Services to you, for analysis and development of products and/or services, for conducting research or preparing statistical data, for the sale promotion and public relations of the Company, for business administration, for fraud prevention, to allow others to provide support services to the Company, for customer verification and authentication of user’s identity which the Company may disclose to different persons or organizations e.g. affiliated companies, and branch companies, whether in Thailand or abroad, data processors, co-branded business partners, external service providers including commercial banks, customer service providers, information technology system operators, or any third party service providers, which the Company has a legal relationship with, regarding provision of various services from time to time, representative of the Company, subcontractors, other financial institutions, auditors, external auditors, credit scoring company, asset management company, credit information company, legal authority, prospective assignees of rights and/or assignees of rights in transactions or mergers of companies, juristic persons or any person that has a relationship with or has a contract with the Company. This includes executives, employees, outsourced employees, contractors, agents, consultants of the Company, and those of persons or entities receiving such information from the Company.
                  <br /><br />
                </MKTypography>
                <MKTypography variant="h4" fontWeight="bold" color="text">
                  <strong>5. CROSS-BORDER TRANSFER OF YOUR PERSONAL DATA</strong><br /><br />
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  The Company may need to transmit or transfer your Personal Data to affiliated companies in the foreign countries or to other recipients as part of the Company's normal business operations, such as transmitting or transferring Personal Data to store on server/cloud in different countries.
                  <br /><br />
                  If the destination country has an insufficient standard of protection of Personal Data, the Company will supervise the transmission or transfer of Personal Data in accordance with the laws, and will take necessary and appropriate measures to protect Personal Data in accordance with confidentiality standards, such as having confidentiality agreements with recipients in such countries or in the event that the recipient is deemed affiliated companies, the Company may choose to implement a personal data protection policy that has been reviewed and approved by the relevant legal authority, and will proceed for the transmission or transfer of Personal Data to affiliated companies in the foreign countries in accordance with the said personal data protection policy. However, in some cases, the Company may request your consent to transfer your personal data abroad if consent is required by law.
                  <br /><br />
                </MKTypography>
                <MKTypography variant="h4" fontWeight="bold" color="text">
                  <strong>6. HOW LONG DO WE KEEP YOUR PERSONAL DATA?</strong><br /><br />
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  The Company will keep your Personal Data for the necessary period during your use of our Services or as you have a relationship with the Company, or for the period necessary to achieve the objectives involved in this Policy. This may require the Company to continue keeping such Personal Data afterwards if required or permitted by laws. For example, the Company may store Personal Data in accordance with the Anti-Money Laundering Act, or for proving purpose, in case of potential disputes, within the statute of limitations.
                  <br /><br />
                  The Company will delete or destroy Personal Data, or make it anonymized when it is no longer necessary or at the end of such period.
                  <br /><br />
                </MKTypography>
                <MKTypography variant="h4" fontWeight="bold" color="text">
                  <strong>7. USE OF COOKIES, WEB LOGS AND DEVICE INFORMATION</strong><br /><br />
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  <strong>7.1 Use of Cookies</strong><br /><br />
                  Cookies are files created by websites you visit, which help you to do things easier online by recording your browsing history. The website uses cookies to keep you logged in, remember website preferences and provide relevant content to provide best experiences to the Services as much as possible, and for maintaining and reinforcing security. Cookies are used to store user settings in order to record accessibility and preferences, and examine statistics and usage patterns. Users can choose not to accept cookies. This will result in the users being unable to use some services when the connection of cookies is required, such as the use of services that the user must log in before using such service.
                  <br /><br />
                  <strong>7.2 Logs of web visitors</strong>
                  <br /><br />
                  After the user has accessed to the services, the system will automatically store a record of the user’s IP address, type of browser, browser language and etc. on the Company’s platform. Such data will be used to analyze the user environment, which will allow the Company to be able to provide better services and prevent fraud or unauthorized permission which interferes with the normal operation.
                  <br /><br />
                  <strong>7.3 Device Information</strong>
                  <br /><br />
                  In using the Services, the Company may receive information about the user's device (such as operating system type, version and etc.) where the provisions of the relevant laws and regulations allow that such information will be used to deliver better services, and to identify persons, including preventing fraud or unauthorized permission which interferes with the normal operation.
                  <br /><br />
                </MKTypography>
                <MKTypography variant="h4" fontWeight="bold" color="text">
                  <strong>8. HOW DO WE PROTECT YOUR PERSONAL DATA?</strong><br /><br />
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  The Company will do our best to keep your Personal Data in accordance with technical measures, organizational measures and physical measures with regard to accessing or controlling access to Personal Data to maintain a confidentiality, completeness and the availability of Personal Data to prevent loss, unauthorized or unlawful change, alteration, use, disclosure or access to Personal Data according to the applicable laws.
                  <br /><br />
                  The Company has established policies, regulations and rules for Personal Data protection such as security standards of information technology systems. In particular, the Company has put in place safe and appropriate access control measures for the collection, use and disclosure of Personal Data, measures to limit access to Personal Data and the use of devices for storing and processing Personal Data by specifying the right to access to the user’s data, right to grant permission to those who are authorized to access such data, and manage access to limit access to Personal Data to only permitted persons, and designate the user's responsibilities to prevent unauthorized access to Personal Data, disclosure, penetration or illegal copying of Personal Data, or theft of Personal Data storage or processing equipments. This also includes taking measures for retrospective review of access, change, deletion or transfer of Personal Data to be consistent with the method and the media used for collecting, using and/or disclosing Personal Data. The company has also updated the policies, rules and regulations periodically as necessary and appropriate.
                  <br /><br />
                  In addition, executives, employees, outsourced employees, contractors, agents, consultants and recipients of the Personal Data from the Company have a duty to maintain the confidentiality of Personal Data in accordance with the confidentiality measures established by the Company.
                  <br /><br />
                </MKTypography>
                <MKTypography variant="h4" fontWeight="bold" color="text">
                  <strong>9. WHAT ARE YOUR RIGHTS REGARDING PERSONAL DATA?</strong><br /><br />
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  Your rights in this section are legal rights that you should know. You can apply for different right currently set forth now or to be amended in the future, as well as the rules set by the Company. In the event that you are not over 20 years old or are limited in the ability to do legal acts, you can request to exercise your right by granting your parents, guardians or representatives to inform the Company on your behalf.
                  <br /><br />
                  <strong>
                    9.1 Right to withdraw consent: If you have given your consent for the Company to collect, use and/or disclose your Personal Data (whether the consent you have given before the date on which the Personal Data Protection Law comes into force or thereafter), you have the right to withdraw your consent at any time during the period your Personal Data is kept with the Company unless there is a limitation on that right by law or there exists a contract that benefits you.
                  </strong>
                  <br /><br />
                  However, withdrawing your consent may affect your use of the product and/or services, such as you will not receive benefits, news, promotions or offers, or not receive a product or service that is better and more consistent with your needs or do not receive useful information for you. For your benefit, it is advisable to study and inquire about the impact before withdrawing the consent.
                  <br /><br />
                  <strong>
                    9.2 Right to access: You have the right to request access to your Personal Data that is in the responsibility of the Company. You can ask the Company make copies of such information for you; including asking the Company disclosed to you as to how the Company get your Personal Data.
                  </strong>
                  <br /><br />
                  <strong>
                    9.3 Right to request data transfer: You have the right to request your Personal Data in the event that the Company has provided that such Personal Data is in the format which can be read or operated with a tool or device that works automatically, and can use or disclose Personal Data by automatic means. This includes the right to request the Company to transmit or transfer Personal Data in such form to another data controller, where possible, by automated means. You also have the right to request Personal Data that the Company transmits or transfers in such form directly to another data controller unless it cannot be operated due to certain technical reasons.
                  </strong>
                  <br /><br />
                  However, your Personal Data above must be the information that you have given consent to the Company to collect, use and/or disclose or is information that is necessary for the Company to collect, use and/or disclose in order for you to use our products and/or services according to the wishes of which you are a party to the contract with the company or for use in the execution of your request before using the Company's products and/or services or other personal data as required by law
                  <br /><br />
                  <strong>
                    9.4 Right to objection: You have the right to object to the collection, use and/or disclosure of your personal data at any time. If the collection, use and/or disclosure of your Personal Data is made for the purpose of carrying out necessary activities under the Company's legitimate interests, or of another person or juristic person without exceeding the limits that you can reasonably expect or to carry out missions for the public interest and you submit an objection, the Company will continue to collect, use and/or disclose your Personal Data only for the circumstances which Company is able to justify based on the law that such collection, use and/or disclosure is more important than your fundamental rights or in order to assert legal rights, or for compliance with the law, or defending against legal action on a case-by-case basis.
                  </strong>
                  <br /><br />
                  You also have the right to object to the collection, use and/or disclosure of your personal data for marketing-related purposes or for the purpose of scientific research studies history or statistics as well.
                  <br /><br />
                  <strong>
                    9.5 Right to deletion: You have the right to request deletion or destruction of your Personal Data or make such Personal Data non-identifiable to you. If you believe that your Personal Data has been unlawfully collected, used and/or disclosed in accordance with applicable law, or that the Company no longer requires to retain your Personal Data for the purposes involved in this Policy, or when you have exercised the right to withdraw your consent or exercise your right to object as stated above.
                  </strong>
                  <br /><br />
                  <strong>
                    9.6 Right to suspension: You have the right to request to temporarily suspend the use of your Personal Data, as opposed to exercise your right to suspension, in the event that the Company is in the process of reviewing your request to exercise your right to rectify your Personal Data or right to object or any other cases that the Company no longer requires and has to delete or destroy your Personal Data according to the relevant laws.
                  </strong>
                  <br /><br />
                  <strong>
                    9.7 Right to rectification: You have the right to request that your Personal Data be correct, up-to-date, complete, and not misleading.
                  </strong>
                  <br /><br />
                  <strong>
                    9.8 Right to Complaint: You have the right to lodge a complaint with the relevant legal authority. If you believe that the collection, use and/or disclosure of your Personal Data is in a manner that violates or fails to comply with applicable laws.
                  </strong>
                  <br /><br />
                  You can exercise the rights mentioned above by submitting the request form to exercise rights at https://contact-moneymine.com For the safety of your Personal Data, we may process your identity verification and authentication, and/or your representatives’ before executing your request to exercise your rights. Once the verification and authentication process is complete, we will process your request to exercise your rights promptly.
                  <br /><br />
                  In some instances, if, in our sole discretion, your request to exercise your rights is manifestly unfounded or excessive, we may charge a fee for the fulfillment of such requests.
                  <br /><br />
                  However, exercise of the abovementioned rights may be limited under the applicable laws, and there are some cases where there is a necessity that the Company may refuse or be unable to act upon your request to exercise any of the above rights, such as being required to comply with law or a court order, for public interest, or because the exercise of rights may violate the rights or liberties of other people, and etc. If the Company refuses the above request, the Company will also inform you of the reason for the refusal.
                  <br /><br />
                </MKTypography>
                <MKTypography variant="h4" fontWeight="bold" color="text">
                  <strong>10. HOW CAN YOU CONTACT US AND THE DATA PROTECTION OFFICER?</strong><br /><br />
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  If you have any suggestions or would like to inquire about the details of collecting, using and/or disclosing your Personal Data, including request to exercise your rights under this Policy, you can contact us and/or the Data Protection Officer through the following channels:
                  <br /><br />
                  Customer Service Center
                  <br />
                  Money Mine Company limited
                  <br />
                  Address: 2, Unit No. 1501-1504, 15th Floor, Silom Edge, Silom Road, Suriya Won, Bangrak, Bangkok  10500
                  <br />
                  Data Protection Officer
                  <br /><br />
                  Email : arnut.t@moneymine.co.th
                </MKTypography>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card >
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default PrivacyPolicy;
