/**
=========================================================
* Money Mine React - v2.1.0
=========================================================

* Product Page: https://www.money-mine.com/product/material-kit-react
* Copyright 2023 Money Mine (https://www.money-mine.com)

Coded by www.money-mine.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  defaultProps: {
    disableRipple: false,
  },
};
